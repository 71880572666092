import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import * as actionTypes from './actionTypes';


export const signOut = ({ preserveStorage } = {}) => ({
  type   : actionTypes.SIGN_OUT,
  payload: {
    preserveStorage,
  },
});

export const signOutSuccess = () => ({
  type: actionTypes.SIGN_OUT_SUCCESS,
});

export const signOutError = (error) => ({
  type: actionTypes.SIGN_OUT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const setLoadedIdentity = (loadedIdentity = null) => ({
  type   : actionTypes.SET_LOADED_IDENTITY,
  payload: {
    loadedIdentity,
  },
});

export const chooseCountry = (country) => ({
  type   : actionTypes.CHOOSE_COUNTRY,
  payload: {
    country,
  },
});

export const chooseScope = (scope) => ({
  type   : actionTypes.CHOOSE_SCOPE,
  payload: {
    scope,
  },
});

export const goToApp = (app) => ({
  type   : actionTypes.GO_TO_APP,
  payload: {
    app,
  },
});

//----------------------------------------------------------------------------------------------------------------------

export const fetchOrganizationMemberships = () => ({
  type: actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS,
});

export const fetchOrganizationMembershipsSuccess = (organizationMemberships) => ({
  type   : actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS,
  payload: {
    organizationMemberships,
  },
});

export const fetchOrganizationMembershipsError = (error) => ({
  type: actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const getMe = (account = {}) => ({
  type             : actionTypes.GET_ME,
  [WAIT_FOR_ACTION]: actionTypes.GET_ME_SUCCESS,
  [ERROR_ACTION]   : actionTypes.GET_ME_ERROR,
  payload          : {
    account,
  },
});

export const getMeSuccess = (account = {}) => ({
  type   : actionTypes.GET_ME_SUCCESS,
  payload: {
    account,
  },
});

export const getMeError = (error) => ({
  type: actionTypes.GET_ME_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const initAuth = (prompt = null) => ({
  type   : actionTypes.INIT_AUTH,
  payload: {
    prompt,
  },
});

