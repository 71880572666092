import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { nl2br } from 'helpers/transformers';
import messages from '../../messages';
import Error from './Error';


class GeneralError extends React.PureComponent {

  static propTypes = {
    code: PropTypes.number,
  };


  render() {
    const { info } = messages.errorPage.general;
    return (
      <Error
        code={this.props.code}
        codeLabel={messages.errorPage.errorCode}
        title={messages.errorPage.general.title}
        info={<p>{nl2br(info, 'mb-4')}</p>}
        buttonLabel={messages.errorPage.goToHomePage}
      />
    );
  }

}

export default injectIntl(GeneralError);
