exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3SyRp{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.nsfY4{fill:#30a8ff;height:20px;width:20px}", ""]);

// exports
exports.locals = {
	"icon": "_3SyRp",
	"icon__icon": "nsfY4"
};