import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';
import { SIGN_OUT } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  redirectUrl                        : null,
  isAutoRedirectOnSingleHCPinProgress: null,
  fetching                           : [],
  sending                            : [],
  errors                             : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT),
        errors : unregisterAction(state.errors, actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT),
      };
    }
    case actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT_SUCCESS: {
      const { redirectUrl } = action.payload;
      return {
        ...state,
        redirectUrl,
        fetching: unregisterAction(state.fetching, actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT),
      };
    }
    case actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT),
        errors  : registerAction(state.errors, actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_IS_AUTO_REDIRECT_ON_SINGLE_HCP_IN_PROGRESS: {
      const { isAutoRedirectOnSingleHCPinProgress } = action.payload;

      return {
        ...state,
        isAutoRedirectOnSingleHCPinProgress,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT: {
      return {
        ...initialState,
      };
    }
    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }
  }
}
