import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';


class Form extends React.PureComponent {

  static propTypes = {
    children : PropTypes.node,
    onSubmit : PropTypes.func,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  };


  static defaultProps = {
    onSubmit: () => {},
  };


  submitForm(evt) {
    evt.preventDefault();
    this.props.onSubmit(evt);
  }


  render() {
    return (
      <form
        action="#"
        className={cn(this.props.className)}
        onSubmit={(evt) => this.submitForm(evt)}
      >
        { this.props.children }
      </form>
    );
  }

}


export default Form;
