import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { call, select, getContext } from 'redux-saga/effects';
import OidcService from 'services/OidcService';
import { ApiError } from 'helpers/errorTypes';
import { regionName, originalRegionName } from 'modules/App/selectors';


/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response, options) {
  let json;
  try {
    json = response.text().then((text) => (text ? JSON.parse(text) : {}));
  } catch (err) {
    throw new ApiError({
      url             : get(response, 'url'),
      status          : 500,
      error           : err,
      validationErrors: {},
      options         : omit(pick(options, ['method', 'headers']), 'headers.Authorization'),
    });
  }
  if (response.status >= 200 && response.status < 300) {
    return json;
  }
  return json.then((resp) => {
    throw new ApiError({
      url             : get(response, 'url'),
      status          : get(response, 'status'),
      validationErrors: get(resp, 'validationErrors'),
      businessError   : get(resp, 'businessError') || get(resp, 'message'),
      options         : omit(pick(options, ['method', 'headers']), 'headers.Authorization'),
    });
  });
}


function* request(url, options, region, fetch) {
  const token = yield call(OidcService.getToken);
  if (!fetch) {
    fetch = yield getContext('fetch');
  }

  options = {
    ...options,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : null),
      ...get(options, 'headers', {}),
    },
  };

  if (url.startsWith('/api')) {
    url = url.replace('/api', `/api/${region}`);
  }

  let response;

  try {
    response = yield call(fetch, url, options);
  } catch (err) {
    throw new ApiError({
      url,
      status          : 503,
      error           : `${err}`,
      validationErrors: {},
      options         : omit(pick(options, ['method', 'headers']), 'headers.Authorization'),
    });
  }

  return yield call(parseJSON, response, options);
}


function* originalRequest(url, options, fetch = null) {
  const region = yield select(originalRegionName);
  return yield call(request, url, options, region, fetch);
}


function* regionalRequest(url, options, fetch = null) {
  const region = yield select(regionName);
  return yield call(request, url, options, region, fetch);
}


export default {
  originalRequest,
  regionalRequest,
};
