import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import isEmpty from 'lodash/isEmpty';
import times from 'lodash/times';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import find from 'lodash/find';
import get from 'lodash/get';
import MiniSearch from 'minisearch';
import filter from 'lodash/filter';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import CollapsibleGroup from 'components/CollapsibleGroup';
import { AppContext } from 'context';
import messages from '../../messages';
import * as shapes from '../../shapes';
import styles from './Choose.pcss';
import ChooseAccountOrganizationMembershipDropDown from './ChooseAccountOrganizationMembershipDropDown';
import OrganizationRow from './OrganizationRow';


class ChooseAccountOrganizationMembership extends React.PureComponent {

  static contextType = AppContext;


  static getDerivedStateFromProps(props) {
    const { organizations = [], organizationMemberships, formValues } = props;
    const search = get(formValues, 'values.search');
    const miniSearch = new MiniSearch({
      idField      : 'organizationUID',
      fields       : ['name', 'organizationUID', 'zipCode', 'city', 'street'],
      storeFields  : ['city', 'organizationUID', 'countryCode', 'logo', 'name', 'zipCode'],
      extractField : (document, fieldName) => document[fieldName],
      searchOptions: {
        boost : { organizationUID: 3, name: 2, city: 2 },
        fuzzy : 1,
        prefix: true,
      },
    });
    let filteredOrganizations;

    if (search) {
      miniSearch.addAll(organizations);
      filteredOrganizations = miniSearch.search(search);
    } else {
      filteredOrganizations = organizations;
    }

    filteredOrganizations = filter(
      filteredOrganizations,
      (organization) => !find(
        organizationMemberships,
        (userOrganization) => userOrganization.organizationUID === organization.organizationUID,
      ),
    );

    return {
      filteredOrganizations,
    };
  }


  static propTypes = {
    // Explicit props
    organizations     : PropTypes.arrayOf(shapes.organization),
    formValues        : PropTypes.object,
    chosenOrganization: shapes.organization,

    // Explicit actions
    isFetchInProgress: PropTypes.bool.isRequired,
    onSelect         : PropTypes.func,
    onSetFormValue   : PropTypes.func,
    // Implicit props
  };


  constructor(props) {
    super(props);
    this.state = {
      filteredOrganizations: [],
      isDropdownOpen       : true,
    };
  }


  onSelect(organizationUID) {
    this.props.onSelect(
      {
        id   : 'organizationUID',
        value: organizationUID,
      }
    );
    this.closeDropdown();
  }


  get organizations() {
    return this.state.filteredOrganizations;
  }


  renderSearch() {
    return (
      <>
        <FormGroup
          id="search"
          formValues={this.props.formValues}
          className={styles.search}
        >
          <Input
            placeholder={messages.placeholders.searchOrganization}
            onChange={this.props.onSetFormValue}
          />
        </FormGroup>
      </>
    );
  }


  renderClinicPlaceholder(idx) {
    return (
      <li key={`i${idx}`} className={`${styles.organization} ${styles['organization--placeholder']}`}>
        <div className={`${styles.organization__row} row fadingLoader`}>
          <div className="col-auto">
            <div className={styles.organization__logo} />
          </div>
          <div className="col">
            <p className={`${styles.organization__name} mb-5`} />
            <p className={`${styles.organization__property} mb-3`} />
            <p className={`${styles.organization__property} mb-3`} />
          </div>
          <div className={`${styles.organization__col__button} col-2`}>
            <p className={`${styles.organization__button} w-100`} />
          </div>
        </div>
      </li>
    );
  }


  renderOrganization(organization) {
    return (
      <li key={organization.organizationUID} className={styles.organization}>
        <div className="row">
          <OrganizationRow organization={organization} onSelect={(organizationUID) => this.onSelect(organizationUID)} />
        </div>
      </li>
    );
  }


  renderOrganizations() {
    if (this.props.isFetchInProgress) {
      return (
        <ul className={styles.organizations}>
          { times(5, (idx) => this.renderClinicPlaceholder(idx)) }
        </ul>
      );
    }
    if (isEmpty(this.organizations)) {
      return null;
    }

    return (
      <ul className={styles.organizations}>
        { map(this.organizations, (organization) => this.renderOrganization(organization)) }
      </ul>
    );
  }


  toggleDropdown() {
    this.setState((prevState) => ({
      isDropdownOpen: !(prevState.isDropdownOpen),
    }));
  }


  closeDropdown() {
    this.setState(() => ({
      isDropdownOpen: false,
    }));
  }


  renderList() {
    return (
      <>
        { this.renderSearch() }
        { this.renderOrganizations() }
      </>
    );
  }

  renderJoinButton() {
    const url = `${this.context.domain}/aid/organization/choose`;
    return (
      <Button
        styleModifier="quaternary"
        type="link"
        labelMessage={messages.buttons.joinToOtherOrganization}
        to={url}
        className="btn--block text--primary mb-0 mt-6"
      />
    );
  }


  renderAnimate() {
    return (
      <CollapsibleGroup
        isOpen={this.state.isDropdownOpen}
      >
        <div
          className={styles.organizationsContainer}
        >
          {this.renderList()}
          {this.renderJoinButton()}
        </div>
      </CollapsibleGroup>
    );
  }


  render() {
    return (
      <>
        <p className="form-label"><FormattedMessage {...messages.labels.organization} /></p>
        <ChooseAccountOrganizationMembershipDropDown
          onClick={() => this.toggleDropdown()}
          chosenOrganization={this.props.chosenOrganization}
        />
        {this.renderAnimate()}
      </>
    );
  }

}


export default withStyles(styles)(ChooseAccountOrganizationMembership);
