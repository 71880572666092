import PropTypes from 'prop-types';
import valuesIn from 'lodash/valuesIn';
import { SCOPE_NAMES, IDENTITY_STATUSES } from './constants';


export const scopeName = PropTypes.oneOf(valuesIn(SCOPE_NAMES));

// const gender = PropTypes.oneOf(['Female', 'Male']);

export const account = PropTypes.shape({
  uuid         : PropTypes.string.isRequired,
  emailHash    : PropTypes.string.isRequired,
  accountSource: PropTypes.string.isRequired,
});

export const scope = PropTypes.shape({
  scopeId: PropTypes.number.isRequired,
  scope  : scopeName.isRequired,
  nameKey: PropTypes.string.isRequired,
});

export const identityProvider = PropTypes.shape({
  identityProviderId  : PropTypes.number.isRequired,
  identityProviderKey : PropTypes.string.isRequired,
  identityProviderType: PropTypes.string.isRequired,
  nameKey             : PropTypes.string.isRequired,
  countries           : PropTypes.arrayOf(PropTypes.string).isRequired,
  scopes              : PropTypes.arrayOf(scopeName).isRequired,
});

export const identityProviderType = PropTypes.oneOf(['Aid', 'ContourCloud', 'CNC', 'Draft']);

export const identity = PropTypes.shape({
  identityProviderType,
  countryCode: PropTypes.string.isRequired,
  status     : PropTypes.oneOf(valuesIn(IDENTITY_STATUSES)).isRequired,
  scope      : scopeName.isRequired,
});


const informationFieldObj = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'string', 'integer', 'decimal', 'bool',
    'date', 'email', 'image',
    'group',
  ]).isRequired,
  isMandatory: PropTypes.bool,
  isMultiple : PropTypes.bool,
  masterFor  : PropTypes.string,
  maxLength  : PropTypes.number, // optional, only for string-like types
  minLength  : PropTypes.number, // optional, only for string-like types
  maxValue   : PropTypes.number, // optional, only for numeric types
  minValue   : PropTypes.number, // optional, only for numeric types
  regExp     : PropTypes.string, // optional
  options    : PropTypes.arrayOf(PropTypes.shape({
    value      : PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    labelKey   : PropTypes.string,
    slaveRegExp: PropTypes.string,
  })),
};

informationFieldObj.fields = PropTypes.arrayOf(PropTypes.shape(informationFieldObj)); // optional, only for group type


export const informationField = PropTypes.shape(informationFieldObj);


export const identityRequirements = PropTypes.shape({
  scope      : scopeName,
  email      : PropTypes.string,
  countryCode: PropTypes.string,
});


export const invitation = PropTypes.shape({
  countryCode     : PropTypes.string.isRequired,
  scope           : scopeName.isRequired,
  allowedScopes   : PropTypes.arrayOf(scopeName).isRequired,
  allowedCountries: PropTypes.arrayOf(PropTypes.string),
  organizationUID : PropTypes.string,
  inviter         : PropTypes.shape({
    firstName       : PropTypes.string.isRequired,
    lastName        : PropTypes.string.isRequired,
    organizationName: PropTypes.string,
  }).isRequired,
});
