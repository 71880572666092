/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import React from 'react';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';


export const formatLocalizationResource = (localizationResources, resourceKey, values = {}) => {
  const localizationResource = get(localizationResources, resourceKey);
  if (!localizationResource) {
    return null;
  }
  const varRegExp = new RegExp(/\{\{[\w]+\}\}/g);
  const message = localizationResource.value;
  const vars = message.match(varRegExp);
  if (!vars) {
    return message;
  }
  const splittedMessage = message.split(varRegExp);
  const combinedMessage = [];

  forEach(splittedMessage, (msg, idx) => {
    combinedMessage.push(msg);
    const variable = vars[idx];
    if (variable) {
      const varName = variable.slice(2, -2);
      const value = get(values, varName, '');
      combinedMessage.push(<b>{ value }</b>);
    }
  });

  return map(combinedMessage, (c, i) => <React.Fragment key={i}>{ c }</React.Fragment>);
};
