export const MODULE_ID = 'app';

export const META_COMPONENT_NAME = 'CNC';

export const SIGNALR_MODULE_ID = 'app-signalR';
export const SIGNALR_HUB_NAME = 'metaBroadcastHub';
export const SIGNALR_NOTIFICATION_RECEIVE_MSG = 'EventReceived';

export const DEFAULT_LOCALE = 'en';

// If you're adding locale see webpack.config.js MomentLocalesPlugin
export const APP_LOCALES = [
  'en-gb',
  'en',
  'en-au',
  'en-ie',
  'ar',
  'bg',
  'cs',
  'de',
  'es',
  'fi',
  'fr',
  'he',
  'hr',
  'it',
  'nl',
  'pl',
  'pt',
  'pt-br',
  'ro',
  'sl',
  'sr',
  'tr',
  'zh-hk',
];

export const APP_LOCALE_LANGUAGES_MAP = {
  'zh-hk': 'zh-Hant',
};

export const TRANSLATIONS_LOCATIONS = [
  'components/Form',
  'modules/App',
  'modules/Account',
  'modules/Layout',
  'modules/ShopifyMultipass',
];

export const CONTACT_EMAIL = 'support@glucocontro.online';

export const LANGUAGE_SELECTOR_DROPDOWN = `${MODULE_ID}/languageSelectorDropdown`;

export const FEATURE_TOGGLES = {
  featureCgm: 'featureCgm',
};
