exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._20G1T{position:relative;margin:0 4rem;padding:4rem 0;background:rgba(0,0,0,0) url(/cnc/assets/svg/general-error.svg) 100% 0 no-repeat;background-size:600px 408px}._2qyXx,.yKc0T{background-image:url(/cnc/assets/svg/not-found.svg)}._1kp6R{background:#fff;width:65%;-webkit-box-shadow:0 4px 16px rgba(50,66,121,.1);box-shadow:0 4px 16px rgba(50,66,121,.1)}._1kp6R p{line-height:1.35}._1kp6R .btn__labelWrapper{padding:0 2rem}._295hD{padding:8rem}._3lxr-{color:#30a8ff;font-weight:700}._2kf7-{font-size:48px;font-size:4.8rem;line-height:1.2;margin-bottom:3rem}", ""]);

// exports
exports.locals = {
	"error": "_20G1T",
	"error--403": "_2qyXx",
	"error--404": "yKc0T",
	"errorInfo": "_1kp6R",
	"errorInfo__inner": "_295hD",
	"errorInfo__errorCode": "_3lxr-",
	"errorInfo__errorTitle": "_2kf7-"
};