import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isObjectLike from 'lodash/isObjectLike';
import ErrorIcon from 'svg/error.svg';

import intlShape from 'shapes/intlShape';

import messages from './messages';


/**
 * Essential macro-like form segment
 *
 * @param {string} id - unique id
 * @param {Element} children - Component children from composition
 * @param {Object} [labelMessage=null] - Intl message label for input label
 * @param {boolean} [isRequired=false] - marker for required fields
 * @param {Object} [formValues] - parent form values state
 * @param {Object} [errorsMessages={}] - Intl message label for input descriptionMessage
 */
class FormGroup extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id          : PropTypes.string.isRequired,
    formValues  : PropTypes.object,
    labelMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    infoMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    errorsMessages: PropTypes.object,
    className     : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    actionElement : PropTypes.element,
    validatorRules: PropTypes.object,
    // boundAsyncData    : PropTypes.any,
    // Implicit props
    children      : PropTypes.element.isRequired,
    intl          : intlShape.isRequired,
  };

  static defaultProps = {
    labelMessage  : null,
    errorsMessages: {},
    // boundAsyncData   : null,
  };


  get isOptional() {
    const { validatorRules, id } = this.props;
    if (!validatorRules) return false;
    return validatorRules && (!validatorRules[id] || !validatorRules[id].includes('required'));
  }


  renderActionElement() {
    if (!this.props.actionElement) {
      return null;
    }
    return <span className="form-action-element">{ this.props.actionElement }</span>;
  }


  renderLabel(hasError) {
    if (!this.props.labelMessage) {
      return null;
    }
    return (
      <label
        className={cn('form-label', {
          'text--error': hasError,
        })}
        htmlFor={this.props.id}
      >
        {
          isObjectLike(this.props.labelMessage)
            ? this.props.intl.formatMessage(this.props.labelMessage, { ...this.props.labelMessage.values })
            : this.props.labelMessage
        }
        { this.renderIsOptional() }
      </label>
    );
  }


  renderInfo() {
    if (!this.props.infoMessage) {
      return null;
    }
    return (
      <p className="form-info">
        {
          isObjectLike(this.props.infoMessage)
            ? this.props.intl.formatMessage(this.props.infoMessage, { ...this.props.infoMessage.values })
            : this.props.infoMessage
        }
      </p>
    );
  }


  renderIsOptional() {
    if (!this.isOptional) {
      return null;
    }
    return <span className="text--disabled">{` - ${this.props.intl.formatMessage(messages.labels.optional)}`}</span>;
  }


  renderErrors(errors) {
    if (!errors) {
      return null;
    }

    const errorsMessages = {
      ...messages.validationErrors,
      ...this.props.errorsMessages,
    };

    return map(errors, (params, error) => (
      <div key={error} className="form-text form-error">
        <ErrorIcon />
        { this.props.intl.formatMessage({ ...errorsMessages[error] }, params) }
      </div>
    ));
  }


  renderBEErrors(errors) {
    if (!errors) {
      return null;
    }
    return map(errors, (params, error) => (
      <p key={error} className="form-text text--error">
        { params }
      </p>
    ));
  }


  render() {
    let value = get(this.props.formValues, ['values', this.props.id]);
    value = value || value === 0 ? value : '';
    const errors = get(this.props.formValues, ['errors', this.props.id], null);
    const BEErrors = get(this.props.formValues, ['BEValidationErrors', this.props.id], null);
    const hasError = !isEmpty(errors) || !isEmpty(BEErrors);
    const input = React.cloneElement(this.props.children, {
      id: this.props.children.props.id ? this.props.children.props.id : this.props.id,
      value,
      hasError,
    });

    return (
      <div className={cn('form-group', this.props.className)}>
        { this.renderLabel(hasError) }
        { this.renderActionElement() }
        <div>
          { React.Children.only(input) }
          { this.renderErrors(errors) }
          { this.renderBEErrors(BEErrors) }
        </div>
        { this.renderInfo() }
      </div>
    );
  }

}

export default injectIntl(FormGroup);
