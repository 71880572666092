import { all, call, getContext, put, select, takeLatest, take } from 'redux-saga/effects';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import moment from 'moment';
import OidcService from 'services/OidcService';
import SessionStorage from 'libs/SessionStorage';
import App from 'modules/App';
import Organization from 'modules/Organization';
import * as ShopifyMultipassActions from 'modules/ShopifyMultipass/actions';
import ApiService from 'services/ApiService';
import clearStorages from 'helpers/clearStorages';
import { getLoadedIdentity, isCNCIdentity } from 'helpers/identity';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import messages from './messages';
import * as constants from './constants';


function* resetLocale() {
  const locale = yield select(App.selectors.locale);
  moment.locale(locale);
  moment.defineLocale('en--account', null);
  if (locale !== 'en') {
    moment.defineLocale(`${locale}--account`, null);
  }
}


function* clearSession(preserveStorage = false) {
  yield all([
    call(clearStorages, preserveStorage),
    call(resetLocale),
    put(App.actions.setSignalRDisconnected()),
  ]);
}


function* signOut({ payload }) {
  try {
    if (!process.env.BROWSER) {
      return;
    }
    const { preserveStorage } = payload;
    yield all([
      call(OidcService.logout),
      call(clearSession, preserveStorage),
    ]);
    yield put(actions.signOutSuccess());
  } catch (err) {
    yield put(actions.signOutError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchOrganizationMemberships() {
  try {
    const requestUrl = '/api/Account/OrganizationMemberships';
    const organizationMemberships = yield call(ApiService.regionalRequest, requestUrl);

    const hcpOrganizationMemberships = filter(organizationMemberships || []);
    yield put(ShopifyMultipassActions.setIsAutoRedirectOnSingleHCPinProgress(hcpOrganizationMemberships.length === 1));
    yield put(actions.fetchOrganizationMembershipsSuccess(organizationMemberships));
  } catch (err) {
    yield put(actions.fetchOrganizationMembershipsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

function* tryAutoRedirectSingleHCPSelect() {
  const accountOrganizationMemberships = yield select(selectors.organizationMemberships) || [];
  if (accountOrganizationMemberships.length !== 1) return false;

  const { organizationUID, identityUID } = accountOrganizationMemberships[0];

  const scope = yield select(selectors.loadedIdentityScope);

  yield put(Organization.actions.fetchHCPOrganizationMemberships(organizationUID,
    scope === constants.SCOPE_NAMES.PROFESSIONAL
      ? identityUID
      : null));
  yield take([Organization.actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS_SUCCESS,
    Organization.actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS_ERROR]);

  const organizationMemberships = yield select(Organization.selectors.organizationHCPs) || [];

  if (organizationMemberships.length === 1) {
    yield put(ShopifyMultipassActions.getShopifyMultipassRedirectUrl(
      accountOrganizationMemberships[0], organizationMemberships[0]
    ));
    const redirect = yield getContext('redirect');
    redirect('redirect-loading');
    return true;
  }
  return false;
}

function* getMe() {
  try {
    const tokenData = yield call(OidcService.getTokenData);
    if (!tokenData) {
      yield put(actions.setLoadedIdentity());
      yield put(actions.getMeSuccess());
      return;
    }
    const loadedIdentity = getLoadedIdentity(tokenData);
    yield put(actions.setLoadedIdentity(loadedIdentity));
    const route = yield select(App.selectors.route);
    const mainUrlRoute = yield select(selectors.mainUrlRoute);

    const getUrl = yield getContext('getUrl');
    const redirect = yield getContext('redirect');

    if (loadedIdentity) {
      const isAvailable = yield isCNCIdentity(loadedIdentity);
      if (
        !isAvailable
      ) {
        const url = getUrl('forbidden');
        redirect(url);
        yield put(actions.getMeSuccess());
        return;
      }
      if (includes(constants.SCOPE_NAMES, loadedIdentity.scope)) {
        yield put(actions.fetchOrganizationMemberships());
      }
    }
    if (includes(constants.SCOPE_NAMES, loadedIdentity.scope) && loadedIdentity) {
      yield put(actions.fetchOrganizationMemberships());
      yield take([actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS,
        actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_ERROR]);
      if (yield tryAutoRedirectSingleHCPSelect()) return;
      yield put(ShopifyMultipassActions.setIsAutoRedirectOnSingleHCPinProgress(false));
    }
    if (
      (
        !mainUrlRoute.isFinal
        && mainUrlRoute.name !== route.name
        && (!mainUrlRoute.alternates || !includes(mainUrlRoute.alternates, route.name))
      )
      || route.name === 'auth'
    ) {
      let url = SessionStorage.getItem('returnPathname');
      if (url) {
        SessionStorage.removeItem('returnPathname');
      } else {
        url = getUrl(mainUrlRoute.name, mainUrlRoute.params);
      }
      redirect(url);
    }
    yield put(actions.getMeSuccess());
  } catch (err) {
    yield put(actions.getMeError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* initAuth({ payload }) {
  try {
    const { prompt } = payload;
    yield call(OidcService.login, prompt);
  } catch (err) {
    console.error(err);
  }
}


function* goToApp({ payload }) {
  try {
    const { app } = payload;
    const bls = yield getContext('bls');
    if (bls[app]) {
      const externalRedirect = yield getContext('externalRedirect');
      externalRedirect(bls[app]);
    }
  } catch (err) {
    yield call(App.dispatchError, err, messages);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.GET_ME, getMe);
  yield takeLatest(actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS, fetchOrganizationMemberships);
  yield takeLatest(actionTypes.INIT_AUTH, initAuth);
  yield takeLatest(actionTypes.GO_TO_APP, goToApp);
  yield takeLatest(actionTypes.SIGN_OUT, signOut);
}


export default [
  sagas,
];
