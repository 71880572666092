import React from 'react';
import { injectIntl } from 'react-intl';
import messages from '../../messages';
import Error from './Error';


class NotFound extends React.PureComponent {


  render() {
    return (
      <Error
        code={404}
        codeLabel={messages.errorPage.errorCode}
        title={messages.errorPage.notFound.title}
        info={messages.errorPage.notFound.info}
        buttonLabel={messages.errorPage.goToHomePage}
      />
    );
  }

}

export default injectIntl(NotFound);
