import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as constants from './constants';
import * as actionTypes from './actionTypes';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
// const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
// const errorsSelector = (state) => state[constants.MODULE_ID].errors;

export const isRedirectInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT),
);


export const isAutoRedirectOnSingleHCPinProgress = createSelector(
  mainSelector,
  (state) => state.isAutoRedirectOnSingleHCPinProgress,
);


//----------------------------------------------------------------------------------------------------------------------


