export const MODULE_ID = 'account';

export const SCOPE_NAMES = {
  PROFESSIONAL: 'Professional',
  CLINIC_STAFF: 'ClinicStaff',
};

export const ALLOWED_COUNTRIES = ['us'];

export const APPS = {
  GCO: {
    name     : 'GlucoContro.online',
    key      : 'gco',
    image    : 'gco.svg',
    scopes   : [SCOPE_NAMES.PROFESSIONAL],
    countries: [],
  },
  CNC: {
    name     : 'ContourNextConcierge.com',
    key      : 'cnc',
    image    : 'cnc.png',
    scopes   : [SCOPE_NAMES.PROFESSIONAL, SCOPE_NAMES.CLINIC_STAFF],
    countries: ['us'],
  },
};

export const IDENTITY_PROVIDER_IMAGES = {
  Aid         : 'gco.svg',
  Cnc         : 'cnc.png',
  ContourCloud: 'cc.svg',
};

export const IDENTITY_STATUSES = {
  AVAILABLE: 'Available',
  ACTIVE   : 'Active',
  INACTIVE : 'Inactive',
};

