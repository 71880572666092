import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ChevronRight from 'svg/chevron-right.svg';
import messages from '../../messages';
import * as shapes from '../../shapes';
import OrganizationRow from './OrganizationRow';


class ChooseAccountOrganizationMembershipDropDown extends React.PureComponent {

  static propTypes = {
    // Explicit props
    onClick           : PropTypes.func,
    // Implicit props
    chosenOrganization: shapes.organization,

  };


  renderField() {
    if (this.props.chosenOrganization) {
      return (
        <div className="row">
          <OrganizationRow organization={this.props.chosenOrganization} />
        </div>
      );
    }
    return (
      <>
        <FormattedMessage {...messages.labels.organizationId} />
        <input
          className="select__input"
          autoComplete="off"
          readOnly
        />
      </>
    );
  }


  render() {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className="select__selected-field-wrapper"
        onClick={this.props.onClick}
      >
        <div className="select__selected-field select__selected-field--no-value h-auto">
          {this.renderField()}
        </div>
        <ChevronRight className="select__arrow" />
      </div>
    );
  }

}

export default ChooseAccountOrganizationMembershipDropDown;
