import App from 'modules/App';
import Account from 'modules/Account';
import ShopifyMultipass from 'modules/ShopifyMultipass';
import Organization from '../modules/Organization';


export function getCoreSagas() {
  return {
    [App.constants.MODULE_ID]             : App.sagas,
    [Account.constants.MODULE_ID]         : Account.sagas,
    [Organization.constants.MODULE_ID]    : Organization.sagas,
    [ShopifyMultipass.constants.MODULE_ID]: ShopifyMultipass.sagas,
  };
}
