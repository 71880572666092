import conformsTo from 'lodash/conformsTo';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import invariant from 'invariant';
import warning from 'warning';

import createReducer from '../store/reducers';

/**
 * Validate the shape of redux store
 */
export function checkStore(store) {
  const shape = {
    dispatch      : isFunction,
    subscribe     : isFunction,
    getState      : isFunction,
    replaceReducer: isFunction,
    runSaga       : isFunction,
    asyncReducers : isObject,
    asyncSagas    : isArray,
  };
  invariant(
    conformsTo(store, shape),
    '(src/helpers...) asyncInjectors: Expected a valid redux store',
  );
}

/**
 * Inject an asynchronously loaded reducer
 */
export function injectAsyncReducer(store, isValid) {
  return function injectReducer(name, asyncReducer) {
    if (!isValid) checkStore(store);

    invariant(
      isString(name) && !isEmpty(name) && isFunction(asyncReducer),
      '(src/helpers...) injectAsyncReducer: Expected `asyncReducer` to be a reducer function',
    );

    if (
      Reflect.has(store.asyncReducers, name)
      // We need to check if it is a real reducer or initial facade only
      && store.asyncReducers[name].toString().match(/^function\s*([^\s(]+)/)
    ) {
      return;
    }

    store.asyncReducers[name] = asyncReducer; // eslint-disable-line no-param-reassign
    store.replaceReducer(createReducer(store.asyncReducers));
  };
}

/**
 * Inject an asynchronously loaded saga
 */
export function injectAsyncSagas(store, isValid) {
  return function injectSagas(name, sagas) {
    if (!isValid) checkStore(store);

    invariant(
      Array.isArray(sagas),
      '(src/helpers...) injectAsyncSagas: Expected `sagas` to be an array of generator functions',
    );

    warning(
      !isEmpty(sagas),
      '(src/helpers...) injectAsyncSagas: Received an empty `sagas` array',
    );

    if (includes(store.asyncSagas, name)) return;

    store.asyncSagas.push(name); // eslint-disable-line no-param-reassign
    sagas.map(store.runSaga);
  };
}

/**
 * Helper for creating injectors
 */
export function getAsyncInjectors(store) {
  checkStore(store);

  return {
    injectReducer: injectAsyncReducer(store, true),
    injectSagas  : injectAsyncSagas(store, true),
  };
}
