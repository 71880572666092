import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import cn from 'classnames';
import intlShape from 'shapes/intlShape';

import Input from './Input';


/**
 * CheckboxRadio component is a wrapper to add additional features to Input component
 * for radio or checkbox functionality
 *
 * @param {string} id - unique input id
 * @param {string} inputValue - child input value
 * @param {string} [value=""] - form value that is connected with input value, indicates if input is checked
 * @param {object} [labelMessage=null] - Intl message definition or plain string for checkbox label
 * @param {object} [descriptionMessage=null] - Intl message definition or plain string for checkbox additional info
 * @param {boolean} [isRadio=false] - indicate whether input is checkbox or radio
 * @param {boolean} [isInline=false] - inline display for inputs lists
 * @param {boolean} [isDisabled=false] - disables checkbox/radio input and mutes its label
 * @param {(string|Object)} [className=''] - additional input classes
 * @param {function} onChange - action that is triggered by input value change
 * @param {function} [onBlur] - action that is triggered when input has been lost focus
 */
class CheckboxRadio extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id          : PropTypes.string,
    inputValue  : PropTypes.string.isRequired,
    value       : PropTypes.string,
    labelMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    descriptionMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isRadio   : PropTypes.bool,
    isInline  : PropTypes.bool,
    isDisabled: PropTypes.bool,
    attributes: PropTypes.object,
    className : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    // Implicit props
    intl    : intlShape.isRequired,
    // Explicit Actions
    onChange: PropTypes.func.isRequired,
    onBlur  : PropTypes.func,
  };


  static defaultProps = {
    id        : '',
    value     : '',
    isRadio   : false,
    isInline  : false,
    isDisabled: false,
    attributes: {},
    className : '',
    onBlur    : () => {},
  };


  get isChecked() {
    return this.props.value === this.props.inputValue;
  }


  renderDescription() {
    if (!this.props.descriptionMessage) {
      return null;
    }
    return (
      <span className="form-text text-muted">
        {
          isObject(this.props.descriptionMessage)
            ? this.props.intl.formatMessage(this.props.descriptionMessage, { ...this.props.descriptionMessage.values })
            : this.props.descriptionMessage
        }
      </span>
    );
  }


  render() {
    const attributes = {
      ...this.props.attributes,
      checked: this.isChecked,
    };

    return (
      <div
        className={cn(
          'form-check',
          {
            'form-check-inline': this.props.isInline,
          },
          this.props.className
        )}
      >
        <label htmlFor={this.props.id} className="form-check-label">
          <div
            className={cn({
              'uniform-checker': !this.props.isRadio,
              'uniform-choice' : this.props.isRadio,
              disabled         : this.props.isDisabled,
            })}
          >
            <div className={cn('form-input__hover', { disabled: this.props.isDisabled })} />
            <span className={cn({ checked: this.isChecked })}>
              <Input
                id={this.props.id}
                type={this.props.isRadio ? 'radio' : 'checkbox'}
                value={this.props.inputValue}
                formControl={false}
                className="form-check-input" // form-check-input-styled
                attributes={attributes}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                isDisabled={this.props.isDisabled}
              />
            </span>
          </div>
          {
            isObject(this.props.labelMessage) && this.props.labelMessage.id
              ? this.props.intl.formatMessage(this.props.labelMessage, { ...this.props.labelMessage.values })
              : this.props.labelMessage
          }
        </label>
        { this.renderDescription() }
      </div>
    );
  }

}

export default injectIntl(CheckboxRadio);
