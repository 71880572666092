import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import Logout from 'svg/logout.svg';
import messages from '../../messages';
import * as actions from '../../actions';
import styles from './LogoutBtn.pcss';


class LogoutBtn extends React.PureComponent {

  static propTypes = {
    // Explicit props
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Implicit actions
    onSignOut: PropTypes.func,
  };


  render() {
    const { className, onSignOut } = this.props;
    return (
      <Button
        styleModifier="primary"
        className={cn(className)}
        onClick={onSignOut}
      >
        <Logout className="btn__icon mr-3" />
        <FormattedMessage {...messages.buttons.logout} />
      </Button>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch(actions.signOut()),
});


const ConnectedLogoutBtn = connect(
  null,
  mapDispatchToProps,
)(LogoutBtn);


export default withStyles(styles)(ConnectedLogoutBtn);
