import { call, put, takeLatest } from 'redux-saga/effects';
import App from 'modules/App';
import ApiService from 'services/ApiService';
import * as actionTypes from './actionTypes';
import * as actions from './actions';


function* fetchHCPOrganizationMemberships({ payload: { organizationUID, hcpIdentityUID } }) {
  try {
    let requestUrl = `/api/Organization/${organizationUID}/memberships`;
    if (hcpIdentityUID) {
      requestUrl += `?hcpIdentityUID=${hcpIdentityUID}`;
    }
    const organizationHCPs = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchHCPOrganizationMembershipsSuccess(organizationHCPs));
  } catch (err) {
    yield put(actions.fetchHCPOrganizationMembershipsError(err));
    yield call(App.dispatchError, err, null);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS, fetchHCPOrganizationMemberships);
}

export default [
  sagas,
];

