/* @flow */

type Fetch = (url: string, options: ?any) => Promise<any>;

type Options = {
  baseUrl: string,
  cookie?: string,
};

/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
function createFetch(
  fetch: Fetch,
  { baseUrl, token }: Options,
) {
  // NOTE: Tweak the default options to suite your application needs
  const defaults = {
    method : 'GET',
    mode   : baseUrl ? 'cors' : 'same-origin',
    // credentials: baseUrl ? 'include' : 'same-origin',
    headers: {
      Accept        : 'application/json',
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : null),
    },
  };

  return async (url: string, options: any) => {

    if (options && options.body) {
      options.body = JSON.stringify(options.body);
    }

    if (url.startsWith('/api')) {
      return fetch(`${baseUrl}${url}`, {
        ...defaults,
        ...options,
        headers: {
          ...defaults.headers,
          ...(options && options.headers),
        },
      });
    }

    if (url.startsWith('http://localhost:12345')) {
      return fetch(url, {
        ...options,
        headers: {
          Accept        : 'application/json',
          'Content-Type': 'application/json',
          ...(options && options.headers),
        },
      });
    }

    return fetch(url, options);

  };
}

export default createFetch;
