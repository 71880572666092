import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import reducer from './reducer';
import sagas, { dispatchError } from './sagas';
import * as selectors from './selectors';
import * as shapes from './shapes';
import messages from './messages';

import AlertsBus from './components/AlertsBus';
import IntlHelmet from './components/IntlHelmet';
import LanguageProvider from './components/LanguageProvider';
import LanguageSelector from './components/LanguageSelector';
import GeneralError from './components/Errors/GeneralError';
import NotFound from './components/Errors/NotFound';
import Forbidden from './components/Errors/Forbidden';


const components = {
  AlertsBus,
  IntlHelmet,
  LanguageProvider,
  LanguageSelector,
  GeneralError,
  NotFound,
  Forbidden,
};


export default {
  actions,
  actionTypes,
  components,
  constants,
  reducer,
  sagas,
  dispatchError,
  selectors,
  shapes,
  messages,
};
