import get from 'lodash/get';
import includes from 'lodash/includes';

import { select } from 'redux-saga/effects';
import { countries } from 'modules/App/selectors';
import { SCOPE_NAMES } from 'modules/Account/constants';


export function getLoadedIdentity(tokenData) {
  return ({
    identityProviderType: get(tokenData, 'identity_identity_provider_type'),
    countryCode         : get(tokenData, 'identity_country_code'),
    status              : 'Active',
    scope               : get(tokenData, 'identity_scope'),
    identityUID         : get(tokenData, 'identityuid'),
  });
}

export function* isCNCIdentity(loadedIdentity) {
  const availableCountries = yield select(countries);
  console.log(availableCountries, loadedIdentity.countryCode);
  const availableCountriesCountryCode = availableCountries.map((country) => country.alpha2Code);
  return includes(SCOPE_NAMES, loadedIdentity.scope)
  && includes(availableCountriesCountryCode, loadedIdentity.countryCode);
}

export default {
  getLoadedIdentity,
  isCNCIdentity,
};
