import { defineMessages } from 'react-intl';


export default defineMessages({
  headers: {
    contourNextConcierge           : { id: 'shopifyMultipass.headers.contourNextConcierge' },
    selectPrescriber               : { id: 'shopifyMultipass.headers.selectPrescriber' },
    selectOrganization             : { id: 'shopifyMultipass.headers.selectOrganization' },
    noteForClinicStaffMember       : { id: 'shopifyMultipass.headers.noteForClinicStaffMember' },
    noteForPrescriber              : { id: 'shopifyMultipass.headers.noteForPrescriber' },
    prescriber                     : { id: 'shopifyMultipass.headers.prescriber' },
    selectOrganizationAndPrescriber: { id: 'shopifyMultipass.headers.selectOrganizationAndPrescriber' },
    IfNotPrescriber                : { id: 'shopifyMultipass.headers.IfNotPrescriber' },
    IfNotStaffMember               : { id: 'shopifyMultipass.headers.IfNotStaffMember' },
    link                           : { id: 'shopifyMultipass.headers.link' },
  },

  buttons: {
    proceed                : { id: 'shopifyMultipass.buttons.proceed' },
    select                 : { id: 'shopifyMultipass.buttons.select' },
    joinToOtherOrganization: { id: 'shopifyMultipass.buttons.joinToOtherOrganization' },
  },

  labels: {
    organization    : { id: 'shopifyMultipass.labels.organization' },
    prescriber      : { id: 'shopifyMultipass.labels.prescriber' },
    organizationId  : { id: 'shopifyMultipass.labels.organizationId' },
    organizationList: { id: 'shopifyMultipass.labels.organizationList' },

  },

  placeholders: {
    organization      : { id: 'shopifyMultipass.placeholders.organization' },
    noOrganization    : { id: 'shopifyMultipass.placeholders.noOrganization' },
    noPrescriber      : { id: 'shopifyMultipass.placeholders.noPrescriber' },
    prescriber        : { id: 'shopifyMultipass.placeholders.prescriber' },
    searchOrganization: { id: 'shopifyMultipass.placeholders.searchOrganization' },
    chooseOrganization: { id: 'shopifyMultipass.placeholders.chooseOrganization' },
  },
  fieldsInfos: {
    ifNotFoundContactSupport                : { id: 'shopifyMultipass.fieldsInfos.ifNotFoundContactSupport' },
    ifNotFoundNeedToOrderAsPrescriber       : { id: 'shopifyMultipass.fieldsInfos.ifNotFoundNeedToOrderAsPrescriber' },
    ifNotFoundNeedToOrderAsPrescriberDetails: { id: 'shopifyMultipass.fieldsInfos.ifNotFoundNeedToOrderAsPrescriberDetails' },
  },
  warningInfos: {
    notAssociated: { id: 'shopifyMultipass.warningInfos.notAssociated' },
  },
});
