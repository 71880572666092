/**
 * Session storage wrapper for SSR
 */
export default class SessionStorage {

  static setItem(key, data) {
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.setItem(key, data);
    }
  }


  static getItem(key) {
    if (typeof sessionStorage !== 'undefined') {
      return sessionStorage.getItem(key);
    }
    return null;
  }


  static removeItem(key) {
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.removeItem(key);
    }
  }


  static clear() {
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.clear();
    }
  }

}
