import React from 'react';
import { injectIntl } from 'react-intl';
import messages from '../../messages';
import Error from './Error';


class Forbidden extends React.PureComponent {


  render() {
    return (
      <Error
        code={403}
        codeLabel={messages.errorPage.errorCode}
        title={messages.errorPage.forbidden.title}
        info={messages.errorPage.forbidden.info}
        buttonLabel={messages.errorPage.goToHomePage}
      />
    );
  }

}

export default injectIntl(Forbidden);
