import * as actionTypes from './actionTypes';


export const getShopifyMultipassRedirectUrl = (chooseOrganization, chosenHCP) => ({
  type   : actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT,
  payload: {
    chooseOrganization,
    chosenHCP,
  },
});

export const getShopifyMultipassRedirectUrlSuccess = (redirectUrl) => ({
  type   : actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT_SUCCESS,
  payload: {
    redirectUrl,
  },
});

export const getShopifyMultipassRedirectUrlError = (error) => ({
  type: actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT_ERROR,
  error,
});


export const setIsAutoRedirectOnSingleHCPinProgress = (isAutoRedirectOnSingleHCPinProgress) => ({
  type   : actionTypes.SET_IS_AUTO_REDIRECT_ON_SINGLE_HCP_IN_PROGRESS,
  payload: {
    isAutoRedirectOnSingleHCPinProgress,
  },
});

//----------------------------------------------------------------------------------------------------------------------
