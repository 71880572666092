import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import * as constants from '../../constants';


const LanguageSelector = () => {
  const dispatch = useDispatch();

  const onSetLocale = (locale) => Promise.all([
    dispatch(actions.setLocale(locale)),
    dispatch(actions.fetchLocalizationResources(locale)),
  ]);

  useEffect(() => {
    onSetLocale(constants.DEFAULT_LOCALE);
  }, []);

  return null;

};

export default LanguageSelector;
