import PropTypes from 'prop-types';


export const organizationMembershipStatus = PropTypes.oneOf(['Active', 'Invitation', 'Pending', 'Rejected']);

export const organizationIdentifier = PropTypes.shape({
  organizationIdentifierType : PropTypes.string,
  organizationIdentifierValue: PropTypes.string,
});

export const organization = PropTypes.shape({
  organizationUID: PropTypes.string.isRequired,
  name           : PropTypes.string.isRequired,
  countryCode    : PropTypes.string.isRequired,
  languageCode   : PropTypes.string,
  logo           : PropTypes.string,
  city           : PropTypes.string,
  street         : PropTypes.string,
  zipCode        : PropTypes.string,
  organizationIdentifier,
}
);

export const accountOrganizationMembership = PropTypes.shape({
  identityUID    : PropTypes.string.isRequired,
  organizationUID: PropTypes.string.isRequired,
  businessEmail  : PropTypes.string.isRequired,
  organization   : organization.isRequired,
});

export const organizationMembership = PropTypes.shape({
  organizationUID: PropTypes.string,
  identityUID    : PropTypes.string,
  accountUID     : PropTypes.string,
  businessEmail  : PropTypes.string,
  account        : PropTypes.shape({
    accountUID: PropTypes.string,
    firstName : PropTypes.string,
    lastName  : PropTypes.string,
    email     : PropTypes.string,
  }),
});


export const organizationWithMemberships = PropTypes.shape({
  organization,
  organizationMembership: PropTypes.arrayOf(accountOrganizationMembership),
});

