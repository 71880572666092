import { MODULE_ID, SIGNALR_MODULE_ID } from './constants';


export const SET_CLIENT_IS_INITIALIZED = `${MODULE_ID}/SET_CLIENT_IS_INITIALIZED`;

export const SET_ORIGINAL_REGION = `${MODULE_ID}/SET_ORIGINAL_REGION`;

export const SET_REGION = `${MODULE_ID}/SET_REGION`;
export const SET_REGION_FROM_TOKEN = `${MODULE_ID}/SET_REGION_FROM_TOKEN`;
export const SET_REGION_SUCCESS = `${MODULE_ID}/SET_REGION_SUCCESS`;
export const SET_REGION_ERROR = `${MODULE_ID}/SET_REGION_ERROR`;

export const SET_COUNTRY = `${MODULE_ID}/SET_COUNTRY`;
export const SET_COUNTRY_BY_CODE = `${MODULE_ID}/SET_COUNTRY_BY_CODE`;
export const SET_LOCALE = `${MODULE_ID}/SET_LOCALE`;
export const SET_ROUTE = `${MODULE_ID}/SET_ROUTE`;
export const SET_TRANSLATIONS = `${MODULE_ID}/SET_TRANSLATIONS`;

export const SET_ALERT = `${MODULE_ID}/SET_ALERT`;
export const DISPLAY_ALERTS = `${MODULE_ID}/DISPLAY_ALERTS`;
export const DISMISS_ALERT = `${MODULE_ID}/DISMISS_ALERT`;
export const DISMISS_SYSTEM_ALERT = `${MODULE_ID}/DISMISS_SYSTEM_ALERT`;

export const OPEN_DROPDOWN = `${MODULE_ID}/OPEN_DROPDOWN`;
export const CLOSE_DROPDOWN = `${MODULE_ID}/CLOSE_DROPDOWN`;

export const OPEN_MODAL = `${MODULE_ID}/OPEN_MODAL`;
export const CLOSE_MODAL = `${MODULE_ID}/CLOSE_MODAL`;

export const OPEN_FLOATING_MODAL = `${MODULE_ID}/OPEN_FLOATING_MODAL`;
export const ACTIVATE_FLOATING_MODAL = `${MODULE_ID}/ACTIVATE_FLOATING_MODAL`;
export const CLOSE_FLOATING_MODAL = `${MODULE_ID}/CLOSE_FLOATING_MODAL`;

export const CLEAR_FORM = `${MODULE_ID}/CLEAR_FORM`;
export const CLEAR_FORM_ERROR = `${MODULE_ID}/CLEAR_FORM_ERROR`;
export const SET_FORM_INPUT_VALUE = `${MODULE_ID}/SET_FORM_INPUT_VALUE`;
export const SET_FORM_VALUES = `${MODULE_ID}/SET_FORM_VALUES`;
export const UNSET_FORM_VALUES = `${MODULE_ID}/UNSET_FORM_VALUES`;
export const SET_FORM_ERRORS = `${MODULE_ID}/SET_FORM_ERRORS`;
export const SET_FORM_BE_ERRORS = `${MODULE_ID}/SET_FORM_BE_ERRORS`;
export const SET_FORM_CONTEXT_DATA = `${MODULE_ID}/SET_FORM_CONTEXT_DATA`;
export const START_FORM_PROCESSING = `${MODULE_ID}/START_FORM_PROCESSING`;

export const FETCH_REGIONS = `${MODULE_ID}/FETCH_REGIONS`;
export const FETCH_REGIONS_SUCCESS = `${MODULE_ID}/FETCH_REGIONS_SUCCESS`;
export const FETCH_REGIONS_ERROR = `${MODULE_ID}/FETCH_REGIONS_ERROR`;

export const FETCH_COUNTRIES = `${MODULE_ID}/FETCH_COUNTRIES`;
export const FETCH_COUNTRIES_SUCCESS = `${MODULE_ID}/FETCH_COUNTRIES_SUCCESS`;
export const FETCH_COUNTRIES_ERROR = `${MODULE_ID}/FETCH_COUNTRIES_ERROR`;

export const FETCH_COUNTRY_SETTINGS = `${MODULE_ID}/FETCH_COUNTRY_SETTINGS`;
export const FETCH_COUNTRY_SETTINGS_SUCCESS = `${MODULE_ID}/FETCH_COUNTRY_SETTINGS_SUCCESS`;
export const FETCH_COUNTRY_SETTINGS_ERROR = `${MODULE_ID}/FETCH_COUNTRY_SETTINGS_ERROR`;

export const FETCH_DEVICES = `${MODULE_ID}/FETCH_DEVICES`;
export const FETCH_DEVICES_SUCCESS = `${MODULE_ID}/FETCH_DEVICES_SUCCESS`;
export const FETCH_DEVICES_ERROR = `${MODULE_ID}/FETCH_DEVICES_ERROR`;

export const FETCH_LANGUAGES = `${MODULE_ID}/FETCH_LANGUAGES`;
export const FETCH_LANGUAGES_SUCCESS = `${MODULE_ID}/FETCH_LANGUAGES_SUCCESS`;
export const FETCH_LANGUAGES_ERROR = `${MODULE_ID}/FETCH_LANGUAGES_ERROR`;

export const FETCH_LOCALIZATION_RESOURCES = `${MODULE_ID}/FETCH_LOCALIZATION_RESOURCES`;
export const FETCH_LOCALIZATION_RESOURCES_SUCCESS = `${MODULE_ID}/FETCH_LOCALIZATION_RESOURCES_SUCCESS`;
export const FETCH_LOCALIZATION_RESOURCES_ERROR = `${MODULE_ID}/FETCH_LOCALIZATION_RESOURCES_ERROR`;

export const SET_PRINT_MODE = `${MODULE_ID}/SET_PRINT_MODE`;

export const DOWNLOAD = `${MODULE_ID}/DOWNLOAD`;
export const DOWNLOAD_SUCCESS = `${MODULE_ID}/DOWNLOAD_SUCCESS`;
export const DOWNLOAD_ERROR = `${MODULE_ID}/DOWNLOAD_ERROR`;

export const UPLOAD = `${MODULE_ID}/UPLOAD`;
export const UPLOAD_PROGRESS = `${MODULE_ID}/UPLOAD_PROGRESS`;
export const UPLOAD_SUCCESS = `${MODULE_ID}/UPLOAD_SUCCESS`;
export const UPLOAD_ERROR = `${MODULE_ID}/UPLOAD_ERROR`;
export const REMOVE_UPLOAD = `${MODULE_ID}/REMOVE_UPLOAD`;
export const CLEAR_UPLOADS = `${MODULE_ID}/CLEAR_UPLOADS`;

export const SIGNALR_CREATE_HUB = `${MODULE_ID}/SIGNALR_CREATE_HUB`;
export const SIGNALR_SET_CONNECTED = `${MODULE_ID}/SIGNALR_SET_CONNECTED`;
export const SIGNALR_SET_DISCONNECTED = `${MODULE_ID}/SIGNALR_SET_DISCONNECTED`;
export const SIGNALR_ERROR = `${MODULE_ID}/SIGNALR_ERROR`;

// signalR BE notifications
export const NEW_INSTANT_NOTIFICATION = `${SIGNALR_MODULE_ID}/NEW_INSTANT_NOTIFICATION`;
export const NEW_CONFIGURATION_VERSION = `${SIGNALR_MODULE_ID}/NEW_CONFIGURATION_VERSION`;

export const SET_NEW_CONFIGURATION_VERSION = `${MODULE_ID}/SET_NEW_CONFIGURATION_VERSION`;

export const WEBSOCKET_START = `${MODULE_ID}/WEBSOCKET_START`;
export const WEBSOCKET_STOP = `${MODULE_ID}/WEBSOCKET_STOP`;
export const WEBSOCKET_SEND = `${MODULE_ID}/WEBSOCKET_SEND`;
export const WEBSOCKET_STORE_STATE = `${MODULE_ID}/WEBSOCKET_STORE_STATE`;

export const LOAD_GTM = `${MODULE_ID}/LOAD_GTM`;
export const LOAD_GTM_SUCCESS = `${MODULE_ID}/LOAD_GTM_SUCCESS`;
export const LOAD_GTM_FAILED = `${MODULE_ID}/LOAD_GTM_FAILED`;

export const LOAD_HOTJAR = `${MODULE_ID}/LOAD_HOTJAR`;
export const LOAD_HOTJAR_SUCCESS = `${MODULE_ID}/LOAD_HOTJAR_SUCCESS`;
export const LOAD_HOTJAR_FAILED = `${MODULE_ID}/LOAD_HOTJAR_FAILED`;

export const FETCH_SYSTEM_ALERTS = `${MODULE_ID}/FETCH_SYSTEM_ALERTS`;
export const FETCH_SYSTEM_ALERTS_SUCCESS = `${MODULE_ID}/FETCH_SYSTEM_ALERTS_SUCCESS`;
export const FETCH_SYSTEM_ALERTS_ERROR = `${MODULE_ID}/FETCH_SYSTEM_ALERTS_ERROR`;

export const FETCH_SYSTEM_ALERTS_SETTINGS = `${MODULE_ID}/FETCH_SYSTEM_ALERTS_SETTINGS`;
export const FETCH_SYSTEM_ALERTS_SETTINGS_SUCCESS = `${MODULE_ID}/FETCH_SYSTEM_ALERTS_SETTINGS_SUCCESS`;
export const FETCH_SYSTEM_ALERTS_SETTINGS_ERROR = `${MODULE_ID}/FETCH_SYSTEM_ALERTS_SETTINGS_ERROR`;

export const SET_FEATURE_TOGGLE = `${MODULE_ID}/SET_FEATURE_TOGGLE`;

export const PUSH_VIRTUAL_PAGEVIEW = `${MODULE_ID}/PUSH_VIRTUAL_PAGEVIEW`;
