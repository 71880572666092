import { MODULE_ID } from './constants';


export const SET_LOADED_IDENTITY = `${MODULE_ID}/SET_LOADED_IDENTITY`;
export const CHOOSE_COUNTRY = `${MODULE_ID}/CHOOSE_COUNTRY`;
export const CHOOSE_SCOPE = `${MODULE_ID}/CHOOSE_SCOPE`;
export const GO_TO_APP = `${MODULE_ID}/GO_TO_APP`;

export const FETCH_ORGANIZATION_MEMBERSHIPS = `${MODULE_ID}/FETCH_ORGANIZATION_MEMBERSHIPS`;
export const FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS = `${MODULE_ID}/FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS`;
export const FETCH_ORGANIZATION_MEMBERSHIPS_ERROR = `${MODULE_ID}/FETCH_ORGANIZATION_MEMBERSHIPS_ERROR`;

export const INIT_AUTH = `${MODULE_ID}/INIT_AUTH`;

export const GET_ME = `${MODULE_ID}/GET_ME`;
export const GET_ME_SUCCESS = `${MODULE_ID}/GET_ME_SUCCESS`;
export const GET_ME_ERROR = `${MODULE_ID}/GET_ME_ERROR`;

export const SIGN_OUT = `${MODULE_ID}/SIGN_OUT`;
export const SIGN_OUT_SUCCESS = `${MODULE_ID}/SIGN_OUT_SUCCESS`;
export const SIGN_OUT_ERROR = `${MODULE_ID}/SIGN_OUT_ERROR`;

