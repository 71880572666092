import { MODULE_ID } from './constants';


export const GET_SHOPIFY_MULTIPASS_REDIRECT = `${MODULE_ID}/GET_SHOPIFY_MULTIPASS_REDIRECT`;
export const GET_SHOPIFY_MULTIPASS_REDIRECT_SUCCESS = `${MODULE_ID}/GET_SHOPIFY_MULTIPASS_REDIRECT_SUCCESS_SUCCESS`;
export const GET_SHOPIFY_MULTIPASS_REDIRECT_ERROR = `${MODULE_ID}/GET_SHOPIFY_MULTIPASS_REDIRECT_ERROR`;

export const SET_IS_AUTO_REDIRECT_ON_SINGLE_HCP_IN_PROGRESS
    = `${MODULE_ID}/SET_IS_AUTO_REDIRECT_ON_SINGLE_HCP_IN_PROGRESS`;

