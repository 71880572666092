import Cookies from 'universal-cookie';
import SessionStorage from 'libs/SessionStorage';


// Silver tape for better GCO safety
export default function (preserveStorage) {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/' });
  cookies.remove('hash', { path: '/' });
  if (!preserveStorage) {
    SessionStorage.clear();
  }
}
