const dataLayerKey = 'dataLayer';

/**
 * Google Tag Manager setup reference:
 * https://developers.google.com/tag-manager/quickstart
 */
const loadGTM = (gtmId) => {
  const s = 'script';
  const tagId = 'google-tag-manager';
  const f = document.getElementsByTagName(s)[0];
  if (document.getElementById(tagId)) {
    return Promise.resolve(true);
  }
  window[dataLayerKey] = window[dataLayerKey] || [];
  window[dataLayerKey].push({
    'gtm.start': new Date().getTime(),
    event      : 'gtm.js',
  });
  const j = document.createElement(s);
  const dl = dataLayerKey !== 'dataLayer' ? `&l=${dataLayerKey}` : '';
  j.id = tagId;
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}${dl}`;
  f.parentNode.insertBefore(j, f);
  return Promise.resolve(true);
};


/**
 * Push data to dataLayer
 * @param {object} data - Data to be pushed to dataLayer
 */
const dlPush = (data) => {
  window[dataLayerKey] = window[dataLayerKey] || [];
  window[dataLayerKey].push(data);
  return Promise.resolve(true);
};


/**
 * Push virtual pageview to dataLayer
 * @param {object} [url] - Page url to be set
 */
const vpPush = (pathname, search = '', hash = '') => {
  const url = pathname + search + hash;
  dlPush({
    event                  : 'virtual-pageview',
    'virtual-pageview-page': url,
  });
  return Promise.resolve(true);
};


const exporter = (func) => (
  process.env.BROWSER
    ? func
    : Promise.resolve(true)
);


export default {
  loadGTM: exporter(loadGTM),
  dlPush : exporter(dlPush),
  vpPush : exporter(vpPush),
};
