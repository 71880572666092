import { formatLocalizationResource } from 'helpers/i18n';


export const transformSystemAlerts = (systemAlerts, systemAlertsSettings, localizationResources) => systemAlerts.map(
  (systemAlert) => {
    const systemAlertSetting = systemAlertsSettings.find(
      (alertSetting) => alertSetting.alertConfigurationId === systemAlert.alertConfigurationId,
    );

    if (!systemAlertSetting) {
      return null;
    }

    return {
      ...systemAlert,
      id     : systemAlert.alertId.toString(),
      type   : systemAlertSetting.type.toLowerCase(),
      message: formatLocalizationResource(
        localizationResources,
        systemAlertSetting.resourceKey,
        systemAlert.variables,
      ),
      actions: [],
    };
  },
);
