import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';


class IntlHelmet extends React.PureComponent {

  static propTypes = {
    // Explicit props
    titleMessage      : PropTypes.object,
    descriptionMessage: PropTypes.object,
    // Implicit props
    direction         : shapes.direction,
    locale            : PropTypes.string,
    intl              : PropTypes.object.isRequired,
    children          : PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };


  renderTitle() {
    if (!this.props.titleMessage) {
      return null;
    }
    return <title>{this.props.intl.formatMessage(this.props.titleMessage)}</title>;
  }


  renderDescription() {
    if (!this.props.descriptionMessage) {
      return null;
    }
    return <meta name="description" content={this.props.intl.formatMessage(this.props.descriptionMessage)} />;
  }


  render() {
    return (
      <Helmet
        titleTemplate="%s"
        htmlAttributes={{ lang: this.props.locale, dir: this.props.direction }}
      >
        { this.renderTitle() }
        { this.renderDescription() }
        { this.props.children }
      </Helmet>
    );
  }

}


const mapStateToProps = (state) => ({
  direction: selectors.direction(state),
  locale   : selectors.locale(state),
});


const ConnectedIntlHelmet = connect(
  mapStateToProps,
)(IntlHelmet);


export default injectIntl(ConnectedIntlHelmet);
