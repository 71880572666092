import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import FormGroup from 'components/Form/FormGroup';
import Select from 'components/Form/Select';
import messages from '../../messages';
import { organizationMembership } from '../../shapes';
import styles from './Choose.pcss';


class ChooseOrganizationMemberships extends React.PureComponent {

  static propTypes = {
    // Explicit props
    organizationMemberships: PropTypes.arrayOf(organizationMembership),
    formValues             : PropTypes.object,
    // Explicit actions
    onSetFormValue         : PropTypes.func,
    // Implicit props
  };


  renderLabel({ account:{ firstName, lastName } }) {
    return (
      <span>{firstName} {lastName}</span>
    );
  }


  render() {
    const { organizationMemberships, formValues } = this.props;
    const isAnyMemberships = !!organizationMemberships.length;
    const optionsFrom = organizationMemberships.map((om) =>
      ({
        identityUID: om.identityUID,
        label      : this.renderLabel(om),
      })
    );

    return (
      <FormGroup
        id="identityUID"
        labelMessage={messages.labels.prescriber}
        formValues={formValues}
      >
        <Select
          optionsFrom={optionsFrom}
          valueKey="identityUID"
          labelKey="label"
          noValueMessage={isAnyMemberships
            ? messages.placeholders.prescriber
            : messages.placeholders.noPrescriber}
          isDisabled={!isAnyMemberships}
          onChange={this.props.onSetFormValue}
        />
      </FormGroup>
    );
  }

}


export default withStyles(styles)(ChooseOrganizationMemberships);
