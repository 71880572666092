import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as constants from '../../constants';
import * as selectors from '../../selectors';


class LanguageProvider extends React.PureComponent {

  static propTypes = {
    // Implicit props
    children    : PropTypes.element.isRequired,
    langCode    : PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
  };


  render() {
    const { langCode, translations } = this.props;
    return (
      <IntlProvider
        locale={langCode}
        defaultLocale={constants.DEFAULT_LOCALE}
        messages={translations}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }

}


const mapStateToProps = (state) => ({
  langCode    : selectors.langCode(state),
  translations: selectors.translations(state),
});


export default connect(
  mapStateToProps,
)(LanguageProvider);
