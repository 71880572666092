import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;


export const isOrganizationHCPsFetchInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS),
);

export const organizationHCPs = createSelector(
  mainSelector,
  (state) => state.organizationHCPs,
);

