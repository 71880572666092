import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import indexOf from 'lodash/indexOf';
import isObjectLike from 'lodash/isObjectLike';
import toNumber from 'lodash/toNumber';
import intlShape from 'shapes/intlShape';
import Eye from 'svg/eye.svg';
import EyeOff from 'svg/eye-off.svg';


/**
 * Input component extends simple input
 *
 * @param {string} id - unique input id
 * @param {string} [type="text"] - input type
 * @param {string} [value=""] - input value
 * @param {(string|Object)} [className] - additional input className
 * @param {boolean} [hasError=false] - indicates if input has form errors
 * @param {boolean} [isDisabled=false] - disable input
 * @param {Object} [attributes] - additional input attributes
 * @param {function} onChange - action that is triggered by input value change
 * @param {function} [onBlur] - action that is triggered when input has been lost focus
 */
class Input extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id   : PropTypes.string,
    type : PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    classNameWrapper: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    hasError  : PropTypes.bool,
    isDisabled: PropTypes.bool,
    attributes: PropTypes.object,
    // Implicit props
    intl      : intlShape.isRequired,
    // Explicit Actions
    onChange  : PropTypes.func.isRequired,
    onBlur    : PropTypes.func,
  };


  static defaultProps = {
    type      : 'text',
    value     : '',
    className : '',
    hasError  : false,
    isDisabled: false,
    attributes: {},
    onBlur    : () => {},
  };


  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }


  get isPasswordType() {
    return this.props.type === 'password';
  }


  getValue(evt) {
    if (
      indexOf(['checkbox', 'radio'], this.props.type) !== -1
      && !evt.target.checked
    ) {
      return '';
    }
    if (this.props.type === 'number' && evt.target.value) {
      return toNumber(evt.target.value);
    }
    return evt.target.value;
  }


  renderPlaceholder() {
    if (!this.props.placeholder) {
      return null;
    }
    return isObjectLike(this.props.placeholder)
      ? this.props.intl.formatMessage(this.props.placeholder, { ...this.props.placeholder.values })
      : this.props.placeholder;
  }


  renderShowHidePassword() {
    const { showPassword } = this.state;
    if (!this.isPasswordType) return null;
    if (showPassword) return <Eye onClick={() => this.setState({ showPassword: false })} />;
    return <EyeOff onClick={() => this.setState({ showPassword: true })} />;
  }


  render() {
    const { showPassword } = this.state;
    let { type } = this.props;
    if (this.isPasswordType && showPassword) {
      type = 'text';
    }

    return (
      <div className={cn('input-container', this.props.classNameWrapper)}>
        <input
          id={this.props.id}
          type={type}
          value={this.props.value}
          autoComplete={this.isPasswordType ? 'off' : 'on'}
          className={cn(
            {
              'form-control' : this.props.type !== 'checkbox' && this.props.type !== 'radio',
              'border-danger': this.props.hasError,
            },
            this.props.className,
          )}
          disabled={this.props.isDisabled}
          {...this.props.attributes}
          placeholder={this.renderPlaceholder()}
          onChange={(evt) => this.props.onChange({
            id   : this.props.id,
            value: this.getValue(evt),
          })}
          onBlur={(evt) => this.props.onBlur({
            id   : this.props.id,
            value: this.getValue(evt),
          })}
        />
        { this.renderShowHidePassword() }
      </div>
    );
  }

}


export default injectIntl(Input);
