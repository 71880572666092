import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AppContext } from 'context';
import { regionName } from 'modules/App/selectors';


const Asset = ({ src, alt, className, ...props }) => {
  const { assetUrl } = useContext(AppContext);
  const region = useSelector(regionName);
  const imgSrc = src.includes('data:image') ? src : `${assetUrl}/${region}/${src}`;


  return (
    <img
      alt={alt}
      className={className}
      src={imgSrc}
      {...props}
    />
  );
};

Asset.propTypes = {
  src      : PropTypes.string,
  alt      : PropTypes.string,
  className: PropTypes.string,
};

export default Asset;
