import { defineMessages } from 'react-intl';


export default defineMessages({

  appsDescriptions: {
    cnc: { id: 'account.appsDescriptions.cnc' },
    gco: { id: 'account.appsDescriptions.gco' },
  },

  scopes: {
    ClinicStaff : { id: 'account.scopes.ClinicStaff' },
    Professional: { id: 'account.scopes.Professional' },
  },

  scopeInfos: {
    ClinicStaff : { id: 'account.scopeInfos.ClinicStaff' },
    Professional: { id: 'account.scopeInfos.Professional' },
  },

  headers: {
    accountExists         : { id: 'account.headers.accountExists' },
    appCatalog            : { id: 'account.headers.appCatalog' },
    chooseCountry         : { id: 'account.headers.chooseCountry' },
    chooseScope           : { id: 'account.headers.chooseScope' },
    confirmEmail          : { id: 'account.headers.confirmEmail' },
    confirmEmailSuccess   : { id: 'account.headers.confirmEmailSuccess' },
    confirmEmailFailed    : { id: 'account.headers.confirmEmailFailed' },
    confirmEmailExpired   : { id: 'account.headers.confirmEmailExpired' },
    confirmEmailInProgress: { id: 'account.headers.confirmEmailInProgress' },
    legalConsents         : { id: 'account.headers.legalConsents' },
    loggingOutInProgress  : { id: 'account.headers.loggingOutInProgress' },
    passwordHasBeenChanged: { id: 'account.headers.passwordHasBeenChanged' },
    resetPassword         : { id: 'account.headers.resetPassword' },
    resetPasswordSent     : { id: 'account.headers.resetPasswordSent' },
    seeYouLater           : { id: 'account.headers.seeYouLater' },
    setInformation        : { id: 'account.headers.setInformation' },
    setNewPassword        : { id: 'account.headers.setNewPassword' },
    signIn                : { id: 'account.headers.signIn' },
    signOut               : { id: 'account.headers.signOut' },
    signUp                : { id: 'account.headers.signUp' },
    somethingWrong        : { id: 'account.headers.somethingWrong' },
  },

  buttons: {
    confirmEmail               : { id: 'account.buttons.confirmEmail' },
    confirmEmailCC             : { id: 'account.buttons.confirmEmailCC' },
    confirmEmailExpiredResend  : { id: 'account.buttons.confirmEmailExpiredResend' },
    confirmEmailSuccessContinue: { id: 'account.buttons.confirmEmailSuccessContinue' },
    confirmEmailFailedResend   : { id: 'account.buttons.confirmEmailFailedResend' },
    confirmEmailFailedSupport  : { id: 'account.buttons.confirmEmailFailedSupport' },
    goToLogin                  : { id: 'account.buttons.goToLogin' },
    logout                     : { id: 'account.buttons.logout' },
    changePassword             : { id: 'account.buttons.changePassword' },
    resetPassword              : { id: 'account.buttons.resetPassword' },
    setNewPassword             : { id: 'account.buttons.setNewPassword' },
    signIn                     : { id: 'account.buttons.signIn' },
    signOut                    : { id: 'account.buttons.signOut' },
    signUp                     : { id: 'account.buttons.signUp' },
  },

  labels: {
    changeContourCloudPass     : { id: 'account.labels.changeContourCloudPass' },
    designation                : { id: 'account.labels.designation' },
    email                      : { id: 'account.labels.email' },
    firstName                  : { id: 'account.labels.firstName' },
    lastName                   : { id: 'account.labels.lastName' },
    loginByContourCloud        : { id: 'account.labels.loginByContourCloud' },
    gender                     : { id: 'account.labels.gender' },
    goBack                     : { id: 'account.labels.goBack' },
    female                     : { id: 'account.labels.female' },
    male                       : { id: 'account.labels.male' },
    licenceNumber              : { id: 'account.labels.licenceNumber' },
    licenceType                : { id: 'account.labels.licenceType' },
    marketingConsents          : { id: 'account.labels.marketingConsents' },
    marketingConsentsInfo      : { id: 'account.labels.marketingConsentsInfo' },
    password                   : { id: 'account.labels.password' },
    confirmPassword            : { id: 'account.labels.confirmPassword' },
    newPassword                : { id: 'account.labels.newPassword' },
    oldPassword                : { id: 'account.labels.oldPassword' },
    temporaryPassword          : { id: 'account.labels.temporaryPassword' },
    personalIdentifierType     : { id: 'account.labels.personalIdentifierType' },
    personalIdentifierValue    : { id: 'account.labels.personalIdentifierValue' },
    professionalIdentifierType : { id: 'account.labels.professionalIdentifierType' },
    professionalIdentifierValue: { id: 'account.labels.professionalIdentifierType' },
    registrationPrivacy        : { id: 'account.labels.registrationPrivacy' },
    rememberMe                 : { id: 'account.labels.rememberMe' },
    resendConfirmEmail         : { id: 'account.labels.resendConfirmEmail' },
    sensitiveDataProfiling     : { id: 'account.labels.sensitiveDataProfiling' },
    useContourCloudApp         : { id: 'account.labels.useContourCloudApp' },
  },

  placeholders: {
    email          : { id: 'account.placeholders.email' },
    firstName      : { id: 'account.placeholders.firstName' },
    lastName       : { id: 'account.placeholders.lastName' },
    password       : { id: 'account.placeholders.password' },
    confirmPassword: { id: 'account.placeholders.confirmPassword' },
  },

  fieldsInfos: {
    password: { id: 'account.fieldsInfos.password' },
  },

  infos: {
    backTo                : { id: 'account.infos.backTo' },
    confirmEmail          : { id: 'account.infos.confirmEmail' },
    confirmEmailCC        : { id: 'account.infos.confirmEmailCC' },
    confirmEmailSuccess   : { id: 'account.infos.confirmEmailSuccess' },
    confirmEmailFailed    : { id: 'account.infos.confirmEmailFailed' },
    confirmEmailExpired   : { id: 'account.infos.confirmEmailExpired' },
    confirmEmailInProgress: { id: 'account.infos.confirmEmailInProgress' },
    haveAccount           : { id: 'account.infos.haveAccount' },
    legalConsents         : { id: 'account.infos.legalConsents' },
    loggingOutSuccessful  : { id: 'account.infos.loggingOutSuccessful' },
    noAccount             : { id: 'account.infos.noAccount' },
    profileSecure         : { id: 'account.infos.profileSecure' },
    tryLoggingOutAgain    : { id: 'account.infos.tryLoggingOutAgain' },
  },

  intros: {
    accountExists    : { id: 'account.intros.accountExists' },
    chooseCountry    : { id: 'account.intros.chooseCountry' },
    chooseScope      : { id: 'account.intros.chooseScope' },
    resetPassword    : { id: 'account.intros.resetPassword' },
    resetPasswordSent: { id: 'account.intros.resetPasswordSent' },
  },

  invitationInfo: {
    personToPerson: { id: 'account.invitationInfo.personToPerson' },
    proToPerson   : { id: 'account.invitationInfo.proToPerson' },
    proToPro      : { id: 'account.invitationInfo.proToPro' },
  },

  validatorRules: {
    legalAge: { id: 'account.errors.validatorRules.legalAge' },
  },

  errors: {
    businessErrors: {
      EmailAlreadyExist      : { id: 'account.errors.businessErrors.EmailAlreadyExist' },
      CCUserAlreadyExists    : { id: 'account.errors.businessErrors.EmailAlreadyExist' },
      TokenWrongFormat       : { id: 'account.errors.businessErrors.TokenWrongFormat' },
      UserOrPasswordIncorrect: { id: 'account.errors.businessErrors.UserOrPasswordIncorrect' },
    },
    validatorRules: {
      sameAsPassphrase: { id: 'account.errors.validatorRules.sameAsPassphrase' },
    },
  },

  statuses: {
    Pending: { id: 'account.statuses.Pending' },
    Active : { id: 'account.statuses.Active' },
    Revoked: { id: 'account.statuses.Revoked' },
  },
});
