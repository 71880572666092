import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as constants from './constants';
import * as actionTypes from './actionTypes';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;

/**
 * Selects current user's identity data
 */
export const loadedIdentity = createSelector(
  mainSelector,
  (state) => state.loadedIdentity,
);


export const loadedIdentityScope = createSelector(
  mainSelector,
  (state) => state.loadedIdentity.scope,
);


export const loadedIdentityUID = createSelector(
  mainSelector,
  (state) => state.loadedIdentity.identityUID,
);


/**
 * Selects current user's identity provider
 */
export const identityProviderType = createSelector(
  loadedIdentity,
  (loadedIdentityState) => loadedIdentityState && loadedIdentityState.identityProviderType,
);


export const organizationMemberships = createSelector(
  mainSelector,
  (state) => state.organizationMemberships,
);


export const isOrganizationMembershipsFetchInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
);


export const mainUrlRoute = createSelector(
  (
  ) =>

    ({
      name   : 'choose',
      isFinal: true,
    })
);

//----------------------------------------------------------------------------------------------------------------------


