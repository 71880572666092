import { call, put, getContext, takeLatest } from 'redux-saga/effects';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import messages from './messages';


function getshopifyMultipassRequestData(chosenOrganization, chosenHCP) {
  const { account, identityUID, accountUID } = chosenHCP;
  const { organization, businessEmail } = chosenOrganization;
  return (
    {
      email    : businessEmail,
      firstName: account.firstName,
      lastName : account.lastName,
      addresses: [
        {
          address1   : organization.street,
          city       : organization.city,
          company    : organization.name,
          country    : 'United States',
          countryCode: organization.countryCode.toUpperCase(),
          firstName  : account.firstName,
          lastName   : account.lastName,
          province   : organization.state,
          zip        : organization.zipCode,
        },
      ],
      identityUID,
      accountUID,
    }
  );
}

function* getShopifyMultipassRedirectUrl({ payload:{ chooseOrganization, chosenHCP } }) {
  try {
    const data = getshopifyMultipassRequestData(chooseOrganization, chosenHCP);

    const requestUrl = '/api/Shopify/GetMultipassRedirectUrl';
    const response = yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
      body  : data,
    });
    const externalRedirect = yield getContext('externalRedirect');
    externalRedirect(response.url);

    yield put(actions.getShopifyMultipassRedirectUrlSuccess(response));

  } catch (err) {
    yield put(actions.getShopifyMultipassRedirectUrlError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* sagas() {
  yield takeLatest(actionTypes.GET_SHOPIFY_MULTIPASS_REDIRECT, getShopifyMultipassRedirectUrl);
}


export default [
  sagas,
];
