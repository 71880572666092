import { registerAction, unregisterAction } from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  loadedIdentity         : null,
  organizationMemberships: [],
  fetching               : [],
  sending                : [],
  errors                 : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.GET_ME_SUCCESS: {
      const {
        uuid = null,
        identities = [],
        encryptedPrivateKey = null,
        passphrase = null,
        publicKey = null,
      } = action.payload.account;
      return {
        ...state,
        uuid,
        identities,
        encryptedPrivateKey,
        passphrase,
        publicKey,
      };
    }

    case actionTypes.SET_LOADED_IDENTITY: {
      const { loadedIdentity } = action.payload;
      return {
        ...state,
        loadedIdentity,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_SUCCESS: {
      const { organizationMemberships } = action.payload;
      return {
        ...state,
        organizationMemberships,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.FETCH_ORGANIZATION_MEMBERSHIPS),
      };
    }
    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SIGN_OUT: {
      return {
        ...initialState,
        isAuthenticated: false,
        sending        : registerAction(state.sending, actionTypes.SIGN_OUT),
        errors         : unregisterAction(state.errors, actionTypes.SIGN_OUT),
      };
    }
    case actionTypes.SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case actionTypes.SIGN_OUT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SIGN_OUT),
        errors : registerAction(state.errors, actionTypes.SIGN_OUT),
      };
    }
    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
