import { combineReducers } from 'redux';
import { routerReducer } from 'redux-first-routing';
import App from 'modules/App';
import Account from 'modules/Account';
import ShopifyMultipass from 'modules/ShopifyMultipass';
import Organization from '../modules/Organization';


/**
 * Here go all core non-dynamic reducers
 * @returns {{router: routerReducer}}
 */
export function getCoreReducers() {
  return {
    router                                : routerReducer,
    [App.constants.MODULE_ID]             : App.reducer,
    [Account.constants.MODULE_ID]         : Account.reducer,
    [Organization.constants.MODULE_ID]    : Organization.reducer,
    [ShopifyMultipass.constants.MODULE_ID]: ShopifyMultipass.reducer,

  };
}

export default function createReducer(asyncReducers = {}) {
  const coreReducers = getCoreReducers();
  return combineReducers({
    ...coreReducers,
    ...asyncReducers,
  });
}
