import assign from 'lodash/assign';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import invariant from 'invariant';


/**
 * Local error
 *
 * @class LocalError
 * @param {Object} params The parameters of the error
 * @param {string} params.message General error message
 */
export class LocalError extends Error {

  constructor(params) {
    invariant(
      isPlainObject(params),
      '(src/helpers...) errorTypes: Expected `LocalError` params to be a plain object',
    );
    super();
    const code = get(params, 'code', 'UnknownError');
    this.businessError = { code };
    this.message = get(params, 'message', code);
    this.name = 'LocalError';
    this.getBusinessError = () => this.businessError;
  }

}


/**
 * Api error
 *
 * @class ApiError
 * @param {Object} params The parameters of the error
 * @param {string} params.status Error status code
 * @param {string} params.error General error message
 * @param {Object[]} params.validationErrors Form validation errors
 */
export class ApiError extends Error {

  constructor(params) {
    invariant(
      isPlainObject(params),
      '(src/helpers...) errorTypes: Expected `ApiError` params to be a plain object',
    );
    super();
    assign(this, params);
    this.businessError = get(params, 'businessError', { code: 'UnknownError' });
    this.message = get(params, 'message', this.businessError.code);
    this.name = 'ApiError';
    this.getBusinessError = () => this.businessError;
  }

}
