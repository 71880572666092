import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { SIGN_OUT } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  organizationHCPs: [],
  fetching        : [],
  sending         : [],
  errors          : [],
};

export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {
    case actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS_SUCCESS: {
      const { organizationHCPs } = action.payload;
      return {
        ...state,
        organizationHCPs,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS),
      };
    }
    case actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS),
        errors  : registerAction(state.errors, actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS),
      };
    }
    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT: {
      return {
        ...initialState,
      };
    }
    //------------------------------------------------------------------------------------------------------------------
    default: {
      return state;
    }
  }
}
