/* eslint-disable global-require */

// The top-level (parent) route
const routes = {

  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    // without auth only
    {
      path    : '/cnc',
      children: [
        {
          name: 'choose',
          path: '/choose',
          load: () => import(/* webpackChunkName: 'shopify-multipass' */ './shopify-multipass'),
        },
        {
          name: 'redirect-loading',
          path: '/redirect-loading',
          load: () => import(/* webpackChunkName: 'shopify-multipass' */ './redirect-loading'),
        },
      ],

      async action({ next }) {
        // Execute each child route until one of them return the result
        const route = await next();
        return route;
      },
    },

    // open
    {
      path    : '/cnc',
      children: [
        {
          name: 'auth',
          path: '/auth',
          load: () => import(/* webpackChunkName: 'account' */ './auth'),
        },
        {
          name: 'auth-error',
          path: '/auth-error',
          load: () => import(/* webpackChunkName: 'account' */ './auth'),
        },
        {
          name: 'auth-silent',
          path: '/auth-silent',
          load: () => import(/* webpackChunkName: 'account' */ './sign-in-silent'),
        },
        {
          name: 'logout',
          path: '/logout',
          load: () => import(/* webpackChunkName: 'account' */ './logout'),
        },
        {
          name: 'error',
          path: '/error/:code',
          load: () => import(/* webpackChunkName: 'error' */ './error'),
        },
        {
          name: 'forbidden',
          path: '/forbidden',
          load: () => import(/* webpackChunkName: 'forbidden' */ './forbidden'),
        },
      ],

      async action({ next }) {
        // Execute each child route until one of them return the result
        const route = await next();
        return route;
      },
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      name: 'not-found',
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();
    return route;
  },
};

export default routes;
