import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import startsWith from 'lodash/startsWith';
import history from 'helpers/history';


function isLeftClickEvent(event) {
  return !!event && event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event && (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey));
}

class Link extends React.Component {

  static propTypes = {
    to       : PropTypes.string.isRequired,
    children : PropTypes.node.isRequired,
    isReplace: PropTypes.bool,
    pathState: PropTypes.object,
    onClick  : PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
  };


  get isExternal() {
    return startsWith(this.props.to, 'http') || startsWith(this.props.to, '//');
  }


  handleClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt);
    }

    if (isModifiedEvent(evt) || !isLeftClickEvent(evt)) {
      return;
    }

    if (evt.defaultPrevented === true) {
      return;
    }

    if (this.isExternal) {
      return;
    }

    evt.preventDefault();
    if (this.props.isReplace) {
      history.replace(this.props.to, this.props.pathState);
      return;
    }
    history.push(this.props.to, this.props.pathState);
  }


  render() {
    const { to, children, ...props } = omit(this.props, ['isReplace', 'pathState']);
    return (
      <a href={to} {...props} onClick={(evt) => this.handleClick(evt)}>
        {children}
      </a>
    );
  }

}

export default Link;
