import { call, put, select } from 'redux-saga/effects';
import { setAlert } from 'modules/App/actions';
import { route as routeSelector } from 'modules/App/selectors';


function* dispatchAlert(type, route, isGlobal, message, messageValues, actions, attributes) {
  const currentRoute = yield select(routeSelector);
  if (currentRoute !== route) return;
  yield put(setAlert({ type, isGlobal, message, messageValues, actions, attributes }));
}


function* createAlertDispatcher({ isGlobal = false } = {}) {
  const route = yield select(routeSelector);
  return {
    error  : (...props) => call(dispatchAlert, 'error', route, isGlobal, ...props),
    success: (...props) => call(dispatchAlert, 'success', route, isGlobal, ...props),
    warning: (...props) => call(dispatchAlert, 'warning', route, isGlobal, ...props),
    info   : (...props) => call(dispatchAlert, 'info', route, isGlobal, ...props),
  };
}


export default {
  createAlertDispatcher,
};
