import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import cn from 'classnames';
import shapeIntl from 'shapes/intlShape';
import Form from 'components/Form';
import { AppContext } from 'context';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import Organization from 'modules/Organization';
import App from 'modules/App';
import Info from 'svg/info.svg';
import ErrorRedCircle from 'svg/error-red-cricle.svg';
import { CONTACT_EMAIL } from 'modules/App/constants';
import messages from '../../messages';
import * as shapes from '../../shapes';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import ChooseOrganizationMemberships from './ChooseOrganizationMemberships';
import styles from './Choose.pcss';
import ChooseAccountOrganizationMembership from './ChooseAccountOrganizationMembership';


class Choose extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    isRedirectInProgress                           : PropTypes.bool.isRequired,
    isScopeProfessional                            : PropTypes.bool.isRequired,
    identityUID                                    : PropTypes.string.isRequired,
    intl                                           : shapeIntl,
    accountOrganizationMemberships                 : PropTypes.arrayOf(shapes.accountOrganizationMembership),
    chosenAccountOrganizationMemberships           : shapes.accountOrganizationMembership,
    organizationMemberships                        : PropTypes.arrayOf(shapes.organizationMembership) || [],
    isClientInitialized                            : PropTypes.bool.isRequired,
    isAccountOrganizationMembershipsFetchInProgress: PropTypes.bool.isRequired,
    isOrganizationMembershipsFetchInProgress       : PropTypes.bool.isRequired,
    isAutoRedirectOnSingleHCPinProgress            : PropTypes.bool,
    formValues                                     : PropTypes.object,
    onSetFormValue                                 : PropTypes.func.isRequired,
    // Implicit actions
    onFetchOrganizationMemberships                 : PropTypes.func,
    onSubmit                                       : PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    const { isScopeProfessional, organizationMemberships, isOrganizationMembershipsFetchInProgress } = this.props;
    if (isScopeProfessional && organizationMemberships.length
      && prevProps.isOrganizationMembershipsFetchInProgress
      && !isOrganizationMembershipsFetchInProgress
    ) {
      this.autoCompleteOrganizationMembership(organizationMemberships);
    }
  }


  onSelectOrganization(input) {
    const { isScopeProfessional, identityUID } = this.props;
    this.props.onSetFormValue(input);
    this.props.onFetchOrganizationMemberships(input.value, isScopeProfessional ? identityUID : null);
    if (!isScopeProfessional) {
      this.props.onSetFormValue(this.resetMember);
    }
  }


  get chosenAccountOrganizationMembership() {
    const { formValues, accountOrganizationMemberships } = this.props;
    const organizationUID = get(formValues, 'values.organizationUID');
    if (!organizationUID || !accountOrganizationMemberships) {
      return null;
    }
    return find(accountOrganizationMemberships, { organizationUID });
  }


  get chosenOrganizationMemberships() {
    const { formValues, organizationMemberships } = this.props;
    const identityUID = get(formValues, 'values.identityUID');
    if (!identityUID || !organizationMemberships) {
      return null;
    }
    return find(organizationMemberships, { identityUID });
  }


  get headers() {
    const { isScopeProfessional } = this.props;
    if (isScopeProfessional) {
      return ({
        contourNextConcierge: messages.headers.contourNextConcierge,
        selectA             : messages.headers.selectOrganization,
        ifNotFound          : messages.fieldsInfos.ifNotFoundNeedToOrderAsPrescriber,
        ifNotFoundDetails   : messages.fieldsInfos.ifNotFoundNeedToOrderAsPrescriberDetails,
      });
    }
    return (
      {
        contourNextConcierge: messages.headers.contourNextConcierge,
        selectA             : messages.headers.selectPrescriber,
        ifNotFound          : messages.fieldsInfos.ifNotFoundContactSupport,
      }
    );
  }


  get resetMember() {
    return ({
      id   : 'identityUID',
      value: null,
    });
  }


  get isAssociated() {
    const {
      isClientInitialized, formValues,
      isOrganizationMembershipsFetchInProgress, isAccountOrganizationMembershipsFetchInProgress,
      organizationMemberships, accountOrganizationMemberships,
    } = this.props;

    if (!isClientInitialized
        || isOrganizationMembershipsFetchInProgress
        || isAccountOrganizationMembershipsFetchInProgress) {
      return true;
    }
    const organizationUID = get(formValues, 'values.organizationUID');

    return organizationUID
      ? !!organizationMemberships.length
      : !!accountOrganizationMemberships.length;
  }


  get isFormCompleted() {
    const { formValues } = this.props;
    const organizationUID = get(formValues, 'values.organizationUID');
    const identityUID = get(formValues, 'values.identityUID');
    return organizationUID && identityUID;
  }


  autoCompleteOrganizationMembership() {
    this.props.onSetFormValue({
      id   : 'identityUID',
      value: this.props.identityUID,
    });
  }


  renderButtons() {
    return (
      <Account.components.LogoutBtn className={styles.logoutBtn} />
    );
  }


  renderHeaders() {
    return (
      <div>
        <h1 className="text--h1 text--bold mb-4"><FormattedMessage {...this.headers.contourNextConcierge} /></h1>
        <h3 className="text--h3"><FormattedMessage {...this.headers.selectA} /></h3>
      </div>
    );
  }


  renderNotAssigned() {
    if (this.isAssociated) { return (null); }
    return (
      <div className="form-text form-error mb-8">
        <ErrorRedCircle className={styles.errorIcon} />
        <FormattedMessage {...messages.warningInfos.notAssociated} />
      </div>
    );
  }


  renderChooseOrganizationMembership() {
    const { isScopeProfessional, organizationMemberships, formValues } = this.props;
    if (isScopeProfessional) {
      return null;
    }
    return (
      <ChooseOrganizationMemberships
        organizationMemberships={organizationMemberships}
        onSetFormValue={this.props.onSetFormValue}
        formValues={formValues}
      />
    );
  }


  renderForm() {
    const { isAccountOrganizationMembershipsFetchInProgress, accountOrganizationMemberships, formValues } = this.props;
    const organizations = accountOrganizationMemberships.map((om) => om.organization);
    return (
      <Form
        onSubmit={() =>
          this.props.onSubmit(this.chosenAccountOrganizationMembership, this.chosenOrganizationMemberships)
        }
      >
        <ChooseAccountOrganizationMembership
          organizations={organizations}
          onSelect={(input) => this.onSelectOrganization(input)}
          onSetFormValue={this.props.onSetFormValue}
          chosenOrganization={this.chosenAccountOrganizationMembership
            ? this.chosenAccountOrganizationMembership.organization
            : null}
          formValues={formValues}
          isFetchInProgress={isAccountOrganizationMembershipsFetchInProgress}
        />
        {this.renderChooseOrganizationMembership()}
        {this.renderInfo()}
        <Button
          type="submit"
          styleModifier="primary"
          labelMessage={messages.buttons.proceed}
          className="btn--block btn--filled"
          isDisabled={!this.isFormCompleted}
          isInProgress={this.props.isRedirectInProgress}
        />
      </Form>
    );
  }

  renderInfo() {
    const tooltipText = has(this.headers, 'ifNotFoundDetails') ? this.props.intl.formatMessage(this.headers.ifNotFoundDetails) : '';
    return (
      <p className={cn(styles.disclaimer, 'form-info')}>
        <FormattedMessage
          {...this.headers.ifNotFound}
          values={{
            contactEmail: (
              <a
                href={`mailto:${CONTACT_EMAIL}`}
                type="link"
              >
                <span>{CONTACT_EMAIL}</span>
              </a>),
          }}
        />
        {this.headers.ifNotFoundDetails
            && (
              <Info
                data-for="globalTooltip"
                data-tip={tooltipText}
                className={styles.disclaimer__icon}
              />
            )
              }
        {process.env.BROWSER
              && (
                <>
                  <ReactTooltip
                    id="globalTooltip"
                    place="right"
                    type="info"
                    effect="solid"
                    className="tooltip"
                    multiline
                  />
                </>
              )}
      </p>
    );
  }


  renderContent() {
    return (
      <div className={styles.root}>
        {this.renderButtons()}
        {this.renderHeaders()}
        {this.renderNotAssigned()}
        {this.renderForm()}
      </div>
    );
  }

  render() {
    if (!this.props.isClientInitialized) {
      return null;
    }
    if (this.props.isAutoRedirectOnSingleHCPinProgress !== false) return null;
    return this.renderContent();
  }

}


const mapStateToProps = (state) => {
  const professionalScope = Account.constants.SCOPE_NAMES.PROFESSIONAL;
  const formName = constants.MULTIPASS_FORM;
  return {
    isRedirectInProgress                           : selectors.isRedirectInProgress(state),
    isScopeProfessional                            : Account.selectors.loadedIdentityScope(state) === professionalScope,
    identityUID                                    : Account.selectors.loadedIdentityUID(state),
    isClientInitialized                            : App.selectors.isClientInitialized(state),
    isAccountOrganizationMembershipsFetchInProgress: Account.selectors.isOrganizationMembershipsFetchInProgress(state),
    isOrganizationMembershipsFetchInProgress       : Organization.selectors.isOrganizationHCPsFetchInProgress(state),
    accountOrganizationMemberships                 : Account.selectors.organizationMemberships(state),
    organizationMemberships                        : Organization.selectors.organizationHCPs(state),
    isAutoRedirectOnSingleHCPinProgress            : selectors.isAutoRedirectOnSingleHCPinProgress(state),
    formValues                                     : App.selectors.formSelector(formName)(state),
  };
};


const mapDispatchToProps = (dispatch) => {
  const formName = constants.MULTIPASS_FORM;
  return {
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue({ formName, input })),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors({ formName, errors })),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
    onSubmit        : (accountOrganizationMembership, organizationMemberships) => dispatch(
      actions.getShopifyMultipassRedirectUrl(accountOrganizationMembership, organizationMemberships)
    ),
    onFetchOrganizationMemberships: (accountOrganizationMembership, hcpIdentityUID = null) => dispatch(
      Organization.actions.fetchHCPOrganizationMemberships(accountOrganizationMembership, hcpIdentityUID)
    ),
  };
};


const ConnectedChoose = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Choose));


export default withStyles(styles)(ConnectedChoose);
