
import * as actionTypes from './actionTypes';


export const fetchHCPOrganizationMemberships = (organizationUID, hcpIdentityUID = null) => ({
  type   : actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS,
  payload: {
    organizationUID,
    hcpIdentityUID,
  },
});

export const fetchHCPOrganizationMembershipsSuccess = (organizationHCPs) => ({
  type   : actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS_SUCCESS,
  payload: {
    organizationHCPs,
  },
});

export const fetchHCPOrganizationMembershipsError = (error) => ({
  type: actionTypes.FETCH_HCP_ORGANIZATION_MEMBERSHIPS_ERROR,
  error,
});
