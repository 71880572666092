exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1ZOgB{display:-ms-flexbox!important;display:flex!important;-ms-flex-align:center!important;align-items:center!important;-ms-flex-pack:center!important;justify-content:center!important;width:100%!important;height:100%!important}", ""]);

// exports
exports.locals = {
	"avatar__initials_container": "_1ZOgB"
};